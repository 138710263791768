import React from 'react';
import Photo from '../assets/photo.jpg';
import { FaGithub, FaLinkedin } from 'react-icons/fa';

const AboutMe = () => {
    return (
        <div id='aboutme' className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
            <div className="bg-white shadow-2xl rounded-3xl overflow-hidden">
                <div className="lg:flex">
                    {/* Section de la photo */}
                    <div className="lg:flex-shrink-0">
                        <img
                            className="h-96 w-full object-cover lg:h-full lg:w-96 filter grayscale hover:grayscale-0 transition-all duration-500 ease-in-out transform hover:scale-105"
                            src={Photo}
                            alt="Ma photo de profil"
                        />
                    </div>

                    {/* Section du texte */}
                    <div className="p-12 lg:p-16">
                        <div className="max-w-3xl">
                            <h1 className="text-5xl font-extrabold text-gray-900 tracking-tight mb-8">
                                A propos de moi
                            </h1>

                            <div className="prose prose-lg prose-indigo max-w-none">
                                <p className="text-xl text-gray-500 mb-6 leading-relaxed">
                                    Il y a cinq ans, j'ai découvert pour la première fois le développement web,
                                    une passion qui ne m'a jamais quitté.
                                </p>

                                <p className="text-xl text-gray-500 leading-relaxed">
                                    Aujourd'hui, j'ai acquis une solide maitrise de divers frameworks front-end et back-end.
                                    Bien que ma préférence aille vers le développement front-end, je suis également à l'aise
                                    avec le back-end pour créer des applications web complètes et performantes.
                                </p>
                            </div>

                           
                          <div className="mt-10 flex space-x-4">
  <span className="inline-flex items-center px-4 py-2 rounded-full text-lg font-medium bg-indigo-100 text-indigo-800">
    <a href="https://www.linkedin.com/in/fabien-ivaldi-9772b4245/" className="flex items-center space-x-2">
      <span>LinkedIn</span> 
      <FaLinkedin size={24} />
    </a>
  </span>

  <span className="inline-flex items-center px-4 py-2 rounded-full text-lg font-medium bg-purple-100 text-purple-800">
    <a href="https://github.com/FabienIvaldi" className="flex items-center space-x-2">
      <span>Github</span> 
      <FaGithub size={24} />
    </a>
  </span>
</div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutMe;