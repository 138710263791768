import React, { useEffect, useRef, useState } from 'react';
import { motion, useScroll, useSpring } from 'framer-motion';
import { Briefcase, GraduationCap, Code, Wrench } from 'lucide-react';

const timelineEvents = [
    {
        date: 'Depuis nov. 2023',
        title: 'Développeur, intégrateur web',
        description: 'Web@cadémie by Epitech, Marseille - Formation de 2 ans de niveau bac+2',
        icon: GraduationCap,
        color: 'bg-blue-500'
    },
    {
        date: 'Mai/juil 2023',
        title: 'Langage informatique',
        description: 'Afpa- Apprentissage de PHP, Python, Javascript',
        icon: Code,
        color: 'bg-green-500'
    },
    {
        date: 'Fév 2023',
        title: 'Création de site web / wordpress',
        description: 'Greta - Salon de Provence',
        icon: Code,
        color: 'bg-purple-500'
    },
    {
        date: 'Août 2022',
        title: 'Stage',
        description: 'SOS télé - Réparation de clim et d\'électroménager - Durée : 4 semaines',
        icon: Wrench,
        color: 'bg-red-500'
    },
    {
        date: 'Avril/Août 2022',
        title: 'Prépa Pro en industrie',
        description: 'CFAI Istres - Découverte du métier de l\'industrie.',
        icon: Wrench,
        color: 'bg-red-500'
    },
    {
        date: 'fin 2020 et fin 2021',
        title: 'Stage découverte',
        description: 'CEREMA - Apprentissage HTML et maintenance informatique - Durée : 3 semaines',
        icon: Wrench,
        color: 'bg-red-500'
    },
    {
        date: '2019-2022',
        title: 'BAC PRO Système numérique',
        description: 'Lycée Jean Moulin, Pont de Bouc - Option C Réseaux informatiques & systèmes communicants',
        icon: GraduationCap,
        color: 'bg-orange-500'
    },
    {
        date: '2015-2019',
        title: 'Collège',
        description: 'College Henri Matisse - Saint-MAximin/Albert Camus - Miramas ',
        icon: GraduationCap,
        color: 'bg-orange-500'
    },
  
];

const TimelineCVFabien = () => {
    const [isVisible, setIsVisible] = useState({});
    const containerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start end", "end end"]
    });

    const scaleY = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    setIsVisible(prev => ({
                        ...prev,
                        [entry.target.dataset.index]: entry.isIntersecting
                    }));
                });
            },
            { threshold: 0.1 }
        );

        const elements = containerRef.current.querySelectorAll('.timeline-item');
        elements.forEach(el => observer.observe(el));

        return () => elements.forEach(el => observer.unobserve(el));
    }, []);

    return (
        <div className="max-w-2xl mx-auto p-4" ref={containerRef}>
            <h2 className="text-3xl font-mono mb-10 text-center" id="parcours">Mon parcours jusqu'à maintenant :</h2>

            <div className="relative">
                <motion.div
                    className="absolute left-1/2 transform -translate-x-1/2 w-1 bg-blue-500"
                    style={{
                        height: '100%',
                        scaleY,
                        originY: 0
                    }}
                />
                <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-gray-200" />

                {timelineEvents.map((event, index) => {
                    const Icon = event.icon;
                    return (
                        <div
                            key={index}
                            data-index={index}
                            className="timeline-item relative mb-12"
                        >
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: isVisible[index] ? 1 : 0 }}
                                transition={{ duration: 0.5 }}
                                className="flex items-center justify-center mb-4"
                            >
                                <motion.div
                                    whileHover={{ scale: 1.2 }}
                                    className={`z-10 flex items-center justify-center w-12 h-12 rounded-full ${event.color} text-white shadow-lg`}
                                >
                                    <Icon className="w-6 h-6" />
                                </motion.div>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                animate={{
                                    opacity: isVisible[index] ? 1 : 0,
                                    y: isVisible[index] ? 0 : 50
                                }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                            >
                                <div className="bg-white p-5 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
                                    <div className="text-sm text-blue-500 font-semibold mb-2">{event.date}</div>
                                    <h3 className="text-xl font-bold mb-1">{event.title}</h3>
                                    <p className="text-gray-600">{event.description}</p>
                                </div>
                            </motion.div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TimelineCVFabien;