import React from "react";
import BoxReveal from "./components/TextTitle";
import { IconCloud } from "./components/CloudProps";
import { WordRotate } from "./components/WordRotate";
import AboutmMe from "./components/AboutmMe";
import TimelineCVFabien from "./components/Timeline";
import { BentoGrid, BentoGridItem, HoverEffect } from "./components/Card";
import {
  IconArrowRight,
  IconArrowWaveRightUp,
  IconBoxAlignRightFilled,
  IconBoxAlignTopLeft,
  IconClipboardCopy,
  IconFileBroken,
  IconSignature,
  IconTableColumn,
} from "@tabler/icons-react";

import projet3 from "./assets/projet3.png";
import projet2 from "./assets/projet2.png";
import projet1 from "./assets/projet1.png";
import projet4 from "./assets/projet4.png";
import projet5 from "./assets/projet5.png";
import projet6 from "./assets/projet6.png";
import algo from "./assets/algo.jpg";
import CV from "./assets/CV_FABIEN_IVALDI.pdf";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import FloatingNavbar from "./components/Header";

const slugs = [
  "typescript",
  "javascript",
  "react",
  "html5",
  "css3",
  "express",
  "git",
  "github",
  "visualstudiocode",
  "figma",
  "php",
  "mysql",
  "mongodb",
  "angular",
  "bootstrap",
  "laravel",
  "symphony"
];

const items = [
  {
    title: "MyCinema",
    description: "Premier gros projets qui est un site web pour administrer les cinémas (gérer les abonnements, les séances de film, ... )",
    header: <img src={projet2} alt="MyCinema" className="object-cover w-full h-full min-h-[6rem] rounded-xl" />,
    icon: <IconClipboardCopy className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "MyH5AI",
    description: "Créer un explorateur de fichier dans un navigateur permettant de visualiser les fichiers textes inclus, codé en PHP",
    header: <img src={projet3} alt="MyH5AI" className="object-cover w-full h-full min-h-[6rem] rounded-xl" />,
    icon: <IconFileBroken className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "BattleShip",
    description: "Le but était de débuguer un jeu de bataille naval codé en Javascript",
    header: <img src={projet1} alt="Battleship" className="object-cover w-full h-full min-h-[6rem] rounded-xl" />,
    icon: <IconSignature className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "Site Ecommerce mobalpa ",
    description:
      "Un site ecommerce complet en partenaire avec mobalpa sur la vente d'électromenager ",
    header: <img src={projet4} alt="Mobalpa" className="object-cover w-full h-full min-h-[6rem] rounded-xl" />,
    icon: <IconTableColumn className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "Previously On",
    description: "Un projet ou on doit faire un site pour voir l'avancé de nos séris en utilisant l'api de BetaSerie",
    header: <img src={projet5} alt="Previoulsy On" className="object-cover w-full h-full min-h-[6rem] rounded-xl" />,
    icon: <IconArrowWaveRightUp className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "Position de l'ISS en temps réel",
    description: "Affiche la position de l'ISS sur une map et affiche la longitude et latitude",
    header: <img src={projet6} alt="Iss position" className="object-cover w-full h-full min-h-[6rem] rounded-xl" />,
    icon: <IconBoxAlignTopLeft className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "Algorithme",
    description: "Quelques projets d'algoritmis",
    header: < img src={algo} alt="Algorithme" className="object-cover w-full h-full min-h-[6rem] rounded-xl" />,

    icon: <IconBoxAlignRightFilled className="h-4 w-4 text-neutral-500" />,
  },
];



// Si tu veux implémenter le hook useTheme, assure-toi de l'importer ou de le définir dans ton code

const App = () => {

  return (


    <div className="">
<FloatingNavbar></FloatingNavbar>
<div id='home'></div>
      <div className="flex items-center justify-around min-h-screen overflow-hidden pt-2 flex-col md:flex-row ">
        <div className="flex flex-col justify-center">
          <BoxReveal boxColor={"#5046e6"} duration={0.5}>
            <p className="text-[2.5rem] sm:text-[4rem] md:text-[6.5rem] font-mono font-semibold text-center">
              Fabien Ivaldi<span className="text-[#5046e6]">.</span>
            </p>
          </BoxReveal>

          <BoxReveal boxColor={"#5046e6"} duration={0.5}>
            <h2 className="mt-[1rem] sm:mt-[2rem] text-[2rem] sm:text-[2.5rem] md:text-[3rem] text-center flex items-center font-mono justify-center">
              Apprenti{" "}
              <span className="text-[#5046e6] ml-2 font-mono">
                <WordRotate
                  words={["Développeur Fullstack", "Développeur Backend", "Développeur Frontend"]}
                />
              </span>
            </h2>
          </BoxReveal>

          <BoxReveal boxColor={"#5046e6"} duration={0.5}>
            <a
              href={CV} // Le fichier PDF doit être dans le dossier "public" pour être accessible directement
              download="MonCV.pdf" // Nom par défaut du fichier téléchargé
              className="mt-4 bg-[#5046e6] hover:bg-[#4038b8] text-white rounded-xl p-2 font-mono 
        flex items-center justify-between w-96  sm:w-96 max-w-xs
        transition-transform duration-300 ease-in-out
        transform 
        focus:outline-none focus:ring-2 focus:ring-[#5046e6] focus:ring-opacity-50
        shadow-lg hover:shadow-xl overflow-visible group"
            >
              <span className="text-lg font-semibold">Mon CV</span>
              <IconArrowRight className="w-5 h-5 transition-transform duration-300 group-hover:translate-x-1" />
            </a>
          </BoxReveal>
        </div>

        <IconCloud iconSlugs={slugs} />
      </div>

      <AboutmMe />

      <p className="mx-auto max-w-7xl text-5xl mt-20 font-mono">Quelques projets que j'ai faits :</p>

      <BentoGrid className="mx-auto max-w-7xl my-20">
        {items.map((item, i) => (
          <BentoGridItem
            key={i}
            title={item.title}
            description={item.description}
            header={item.header}
            icon={item.icon}
            className={i === 3 || i === 6 ? "md:col-span-2" : ""}
          />
        ))}
      </BentoGrid>

      <TimelineCVFabien></TimelineCVFabien>
      <footer className="bg-gray-800 bg-opacity-70 text-white py-8 backdrop-filter backdrop-blur-md">
        <div className="max-w-6xl mx-auto px-4"  id='#contact'>
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="text-center md:text-left mb-6 md:mb-0">
              <h2 className="text-3xl font-bold mb-2 font-mono">Contactez-moi !</h2>
              <p className="text-xl font-semibold text-gray-300 font-mono">Envie d'échanger ou collaborer sur un projet ?</p>
              <a
                href="mailto:fabien.ivaldi1@gmail.com"
                className="inline-block mt-4 px-6 py-2 font-mono bg-[#5046e6] text-white font-bold rounded-full hover:bg-blue-600 transition-colors duration-300 ease-in-out"
              >
                Me Contacter
              </a>
            </div>
            <div className="flex flex-col items-center md:items-end">
              <div className="flex gap-4 mb-4">
                <a href="https://github.com/FabienIvaldi" className="text-gray-300 hover:text-white transition-colors duration-300">
                  <FaGithub size={28} />
                </a>
                <a href="https://www.linkedin.com/in/fabien-ivaldi-9772b4245/" className="text-gray-300 hover:text-white transition-colors duration-300">
                  <FaLinkedin size={28} />
                </a>
              </div>
              <p className="text-sm text-gray-400 font-mono">©2024 Fabien Ivaldi</p>
            </div>
          </div>
        </div>
      </footer>





    </div>



  );
};




export default App;
