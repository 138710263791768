import React, { useState, useEffect } from 'react';
import { FaBars, FaArrowUp } from 'react-icons/fa';
import { motion, useAnimation } from 'framer-motion';

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);
    const controls = useAnimation();

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > document.documentElement.scrollHeight - window.innerHeight - 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    useEffect(() => {
        if (isVisible) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 100 });
        }
    }, [isVisible, controls]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <motion.button
            initial={{ opacity: 0, y: 100 }}
            animate={controls}
            onClick={scrollToTop}
            className="fixed bottom-5 right-5 bg-[#5046e6] text-white p-4 rounded-full shadow-lg hover:bg-blue-600 focus:outline-none z-30 transition duration-300 transform hover:scale-105 active:scale-95"
        >
            <FaArrowUp className="text-2xl" />
        </motion.button>

    );
};

const FloatingNavbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const controls = useAnimation();

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY < lastScrollY || currentScrollY < 50) {
                controls.start("visible");
            } else {
                controls.start("hidden");
            }
            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY, controls]);

    const variants = {
        visible: { y: 0, opacity: 1, transition: { duration: 0.3, ease: "easeOut" } },
        hidden: { y: "-100%", opacity: 0, transition: { duration: 0.3, ease: "easeIn" } }
    };

    return (
        <>
            <motion.nav
                initial="visible"
                animate={controls}
                variants={variants}
                className="fixed top-0 left-0 right-0 z-50 bg-white shadow-md"
            >
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center">
                            <a href="/" className="flex-shrink-0">
                                {/* You can add your logo here */}
                            </a>
                            <div className="hidden md:block">
                                <div className="ml-10 flex items-baseline space-x-4">
                                    <a href="#home" className="px-3 py-2 rounded-md text-sm font-medium text-gray-800 hover:bg-gray-200">Accueil</a>
                                    <a href="#aboutme" className="px-3 py-2 rounded-md text-sm font-medium text-gray-800 hover:bg-gray-200">À propos</a>
                                    <a href="#projet" className="px-3 py-2 rounded-md text-sm font-medium text-gray-800 hover:bg-gray-200">Mes projets</a>
                                    <a href="#parcours" className="px-3 py-2 rounded-md text-sm font-medium text-gray-800 hover:bg-gray-200">Mon parcours</a>
                                    <a href="#contact" className="px-3 py-2 rounded-md text-sm font-medium text-gray-800 hover:bg-gray-200">Me contacter</a>
                                </div>
                            </div>
                        </div>
                        <div className="md:hidden">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                            >
                                <FaBars />
                            </button>
                        </div>
                    </div>
                </div>

                <motion.div
                    initial="hidden"
                    animate={isOpen ? "visible" : "hidden"}
                    variants={{
                        visible: { height: "auto", opacity: 1 },
                        hidden: { height: 0, opacity: 0 }
                    }}
                    transition={{ duration: 0.3 }}
                    className="md:hidden overflow-hidden"
                >
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        <a href="#home" className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:bg-gray-200">Accueil</a>
                        <a href="#aboutme" className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:bg-gray-200">À propos</a>
                        <a href="#projet" className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:bg-gray-200">Mes projets</a>
                        <a href="#parcours" className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:bg-gray-200">Mon parcours</a>
                        <a href="#contact" className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:bg-gray-200">Me contacter</a>
                    </div>
                </motion.div>
            </motion.nav>
            <ScrollToTopButton />
        </>
    );
};

export default FloatingNavbar;